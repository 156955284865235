<template>
  <div>
    <v-container fluid fill-height>
      <c-ecosystem-nav :ecosystemId="ecosystemId"></c-ecosystem-nav>

      <v-row>
        <v-col>
          <v-container :fluid="$vuetify.breakpoint.lgAndDown">
            <!-- Title -->
            <v-row class="title-row">
              <v-col offset-sm="1" cols="11" :sm="10">
                <c-page-heading
                  :heading="$t('schedule.events.manage')"
                  :subHeading="$t('schedule.events.events')"
                ></c-page-heading>
              </v-col>
            </v-row>

            <!-- Subtitle -->
            <v-row>
              <v-col offset-sm="1" cols="11" :sm="10" class="text-left">
                <div
                  class="sub-title font-weight-light"
                  data-cy="createAndManageEventsForYourTeamARegularMeetingOrSpecialEvent"
                >
                  {{
                    $t(
                      'schedule.events.createAndManageEventsForYourTeamARegularMeetingOrSpecialEvent'
                    )
                  }}
                </div>
              </v-col>
            </v-row>

            <v-row v-if="loading" class="d-flex justify-center align-center mt-8">
              <c-loading-spinner />
            </v-row>

            <v-row v-else>
              <v-col
                cols="12"
                offset-sm="1"
                :sm="10"
                class="d-flex justify-end align-center mt-8 pr-sm-0"
              >
                <v-btn
                  width="170"
                  height="50"
                  @click.prevent="openUpsertModal"
                  color="primary"
                  class="submit-btn-text border-8"
                  data-cy="create-new-event-button"
                >
                  {{ $t('schedule.events.createNewEvent') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
    <c-event-upsert-modal
      :show="this.$store.getters['events/showUpsertModal']"
      v-on:close="closeUpsertModal"
      :ecosystemId="ecosystemId"
    />
  </div>
</template>
<script>
export default {
  props: ['ecosystemId'],
  data: () => ({
    workspace: null,
    loading: true,
  }),
  methods: {
    closeUpsertModal() {
      this.$store.dispatch('events/toggleUpsertModal', false)
      this.$router.push({ path: `/ws/${this.ecosystemId}/schedule/events` })
    },
    async openUpsertModal() {
      await this.$store.dispatch('events/toggleUpsertModal', true)
      this.$router.push({
        path: `/ws/${this.ecosystemId}/schedule/events`,
        query: { step: 'Details' },
      })
    },
  },
  async mounted() {
    this.loading = true

    this.$store.dispatch('ecosystems/fetchById', this.ecosystemId).then(() => {
      this.workspace = this.$store.getters['ecosystems/getById'](this.ecosystemId)
      if (this.workspace.gatherings.length) {
        this.$store.dispatch('ecosystems/fetchSpaces', this.ecosystemId).then(() => {
          this.workspace = this.$store.getters['ecosystems/getById'](this.ecosystemId)
          this.loading = false
        })
      } else {
        this.loading = false
      }
    })

    // If there is a step in the url, switch the modal on and show the step
    if (this.$route.query.step) {
      this.$store.dispatch('events/toggleUpsertModal', true)
      this.$router.push({
        path: `/ws/${this.ecosystemId}/schedule/events`,
        query: { step: this.$route.query.step },
      })
      // Else if there is no step in the url and the store has the modal switched on, switch it off
    } else if (!this.$route.query.step && (await this.$store.getters['events/showUpsertModal'])) {
      this.$store.dispatch('events/toggleUpsertModal', false)
    }
  },
}
</script>
<style scoped lang="scss">
.sub-title {
  font-size: 1.3rem;
}
.title-row {
  max-height: 120px;
}
.border-8 {
  border-radius: 8px;
}
</style>
